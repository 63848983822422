export const COMMENTS_PER_PAGE_DESKTOP = 20;
export const COMMENTS_PER_PAGE_MOBILE = 20;
export const REPLIES_PER_PAGE = 3;
export const POSTS_PER_PAGE = 20;
export const LIKES_PER_PAGE = 20;
export const MEMBERS_PER_PAGE = 18;
export const SEARCH_RESULTS_PER_PAGE_MOBILE = 10;
export const SEARCH_RESULTS_PER_PAGE_DESKTOP = 20;

export const getCommentsPerPage = isMobile =>
  isMobile ? COMMENTS_PER_PAGE_MOBILE : COMMENTS_PER_PAGE_DESKTOP;

export const getSearchResultsPerPage = isMobile =>
  isMobile ? SEARCH_RESULTS_PER_PAGE_MOBILE : SEARCH_RESULTS_PER_PAGE_DESKTOP;
