import { flowRight } from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import {
  SORT_BY_LAST_ACTIVITY,
  SORT_BY_NEWEST,
  SORT_BY_COMMENTS,
  SORT_BY_MOST_VIEWS,
  SORT_BY_LIKES,
} from '@wix/communities-forum-client-commons/dist/src/constants/sorting';
import { FILTER_SHOW_ALL } from '../../constants/filtering';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import { getCategoryFilter, getPostTypeFilter } from '../../selectors/filter-selectors';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getActiveSiteMemberId } from '../../containers/header-tabs';

export const getOptions = t => [
  { label: t('post-sorting.sortByLastActivity'), value: SORT_BY_LAST_ACTIVITY },
  { label: t('post-sorting.newest'), value: SORT_BY_NEWEST },
  { label: t('post-sorting.comments'), value: SORT_BY_COMMENTS },
  { label: t('post-sorting.views'), value: SORT_BY_MOST_VIEWS },
  { label: t('post-sorting.likes'), value: SORT_BY_LIKES },
];

class SortingSelectHeadless extends Component {
  onChange = value => {
    const {
      fetchCategoryPosts,
      setPostsPageSorting,
      categoryId,
      siteMemberId,
      page,
      filter,
      postTypes,
    } = this.props;
    setPostsPageSorting(value);

    fetchCategoryPosts({
      categoryId,
      siteMemberId,
      page,
      sort: value,
      postType: postTypes.length > 1 ? filter : FILTER_SHOW_ALL,
      pageSize: POSTS_PER_PAGE,
    });
  };

  render() {
    const { t, sort, children } = this.props;
    return children({ options: getOptions(t), value: sort, onChange: this.onChange });
  }
}

SortingSelectHeadless.propTypes = {
  t: PropTypes.func,
  onChange: PropTypes.func,
  fetchCategoryPosts: PropTypes.func,
  setPostsPageSorting: PropTypes.func,
  categoryId: PropTypes.string,
  page: PropTypes.number,
  sort: PropTypes.string,
  filter: PropTypes.string,
  postTypes: PropTypes.array,
  children: PropTypes.func,
  fetchFirstPageOnly: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const { categorySlug } = params;
  const page = parseInt(params.page, 10) || 1;
  const emptyCategory = { _id: null, postTypes: [] };
  const getRouteCategory = () => getCategoryBySlug(state, categorySlug);
  const getFilterCategory = () => getCategoryBySlug(state, getCategoryFilter(state));
  const category = getRouteCategory() || getFilterCategory() || emptyCategory;

  return {
    categoryId: category._id,
    page:
      ownProps.fetchFirstPageOnly || getLayoutType(state, host.style) === LAYOUT_TYPE_PINBOARD
        ? 1
        : page,
    filter: getPostTypeFilter(state),
    sort: getCategoryPageSorting(state, host.style),
    postTypes: category.postTypes,
    fetchCategoryPosts: actions.fetchCategoryPosts,
    setPostsPageSorting: actions.setPostsPageSorting,
    siteMemberId: getActiveSiteMemberId(state),
  };
};

export default flowRight(withTranslate, connect(mapRuntimeToProps))(SortingSelectHeadless);
