import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ArrowDown } from '../icons/arrow-down';
import MoreButton from '../more-button';
import styles from './dropdown-button.scss';

const OpenButton = ({ text, isOpen }) => (
  <div className={classNames(styles.openButton, 'button-primary-text-color')}>
    <span>{text}</span>&nbsp;
    <span
      className={classNames('button-primary-icon-fill', styles.arrow, {
        [styles.arrowUp]: isOpen,
      })}
    >
      <ArrowDown />
    </span>
  </div>
);

export class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.containerRef = React.createRef();
  }

  showComponent = () => {
    const { onDropdownShow } = this.props;
    this.setState({ isOpen: true });
    onDropdownShow && onDropdownShow();
  };

  hideComponent = () => this.setState({ isOpen: false });

  getParentRef = () => this.containerRef;

  render = () => {
    const { className, children, isSmall = true, dataHook, label } = this.props;

    return (
      <div data-hook={dataHook} ref={this.containerRef}>
        <MoreButton
          ariaLabel={label}
          icon={<OpenButton isOpen={this.state.isOpen} text={label} />}
          className={classNames(styles.moreButtonContainer, {
            [styles.small]: isSmall,
          })}
          buttonClassName={classNames(styles.moreButton, 'button-background-color')}
          actionsContainerClassName={classNames(styles.dropdown, className)}
          onHide={this.hideComponent}
          onShow={this.showComponent}
          parentRef={this.getParentRef}
          size={MoreButton.SIZE_EXTENDED}
        >
          {children}
        </MoreButton>
      </div>
    );
  };
}

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  t: PropTypes.func.isRequired,
  isSmall: PropTypes.bool,
  onDropdownShow: PropTypes.func,
};

DropdownButton.defaultProps = {
  dataHook: 'dropdown-select',
};

export default DropdownButton;
