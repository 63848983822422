import PropTypes from 'prop-types';
import React from 'react';
import CreatePostCta from '../create-post-cta';
import ItemContainer from '../post-list-mobile-common/item-container';
import NoPostsFiltered from '../no-posts-filtered';
import { HorizontalSeparator } from '../separator';
import { CategoryPageFiltersBar } from '../filters-bar-mobile';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import PaginationMobile from '../pagination-mobile';
import FollowCard from '../follow-card';
import PostListCard from '../card/post-list-card';

const PostListMobile = ({
  category,
  posts,
  query,
  page,
  entityCount,
  buildPageUrl,
  onPageChange,
  onLikeClick,
  showCreatePostAction,
  showFollowCategoryAction,
  hasActiveFilter,
  uniquePostTypesInAllCategories,
  showFilters,
  showMemberPosts,
  customCtaLabel,
}) => {
  const postTypes =
    uniquePostTypesInAllCategories.length === 1
      ? uniquePostTypesInAllCategories
      : category.postTypes;

  return (
    <div>
      {showCreatePostAction && (
        <React.Fragment>
          <ItemContainer>
            <CreatePostCta
              categorySlug={category.slug}
              postTypes={postTypes}
              createPostCtaLabel={customCtaLabel}
            />
          </ItemContainer>
          <HorizontalSeparator />
        </React.Fragment>
      )}
      {showFilters && <CategoryPageFiltersBar />}
      {!posts.length && hasActiveFilter && <NoPostsFiltered noMemberPosts={showMemberPosts} />}
      {posts.map((post, idx) => (
        <React.Fragment>
          <ItemContainer key={post._id}>
            <PostListCard
              post={post}
              query={query}
              onLikeClick={onLikeClick}
              showRecentActivity
              withContentExcerpt
            />
          </ItemContainer>
          {idx < posts.length - 1 ? <HorizontalSeparator /> : null}
        </React.Fragment>
      ))}
      <PaginationMobile
        page={page}
        entityCount={entityCount}
        showPerPage={POSTS_PER_PAGE}
        onChange={({ page }) => onPageChange(page)}
        buildPageUrl={buildPageUrl}
      />
      {(showCreatePostAction || showFollowCategoryAction) && <HorizontalSeparator />}
      {showCreatePostAction && (
        <React.Fragment>
          <ItemContainer>
            <CreatePostCta
              categorySlug={category.slug}
              postTypes={postTypes}
              createPostCtaLabel={customCtaLabel}
            />
          </ItemContainer>
        </React.Fragment>
      )}
      {showFollowCategoryAction && (
        <FollowCard withoutPaddingTop={showCreatePostAction} category={category} />
      )}
    </div>
  );
};

PostListMobile.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  showCreatePostAction: PropTypes.bool,
  showFollowCategoryAction: PropTypes.bool,
  showFilters: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  uniquePostTypesInAllCategories: PropTypes.array,
  page: PropTypes.number,
  entityCount: PropTypes.number,
  onPageChange: PropTypes.func,
  buildPageUrl: PropTypes.func,
  showMemberPosts: PropTypes.bool,
};

export default PostListMobile;
