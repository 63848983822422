import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import PropTypes from 'prop-types';
import CategoryPage from '../category-page';
import CategoriesPage from '../categories-page';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import { flowRight } from 'lodash';
import { FeedType, getFeedType } from '../header-tabs';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';

class HomePage extends Component {
  render() {
    const { isMainPageEnabled, feedType, isForumTabsEnabled, currentSiteMemberId } = this.props;

    if (!isForumTabsEnabled) {
      return isMainPageEnabled ? (
        <CategoriesPage {...this.props} />
      ) : (
        <CategoryPage {...this.props} />
      );
    }

    if (feedType === FeedType.MyPosts && currentSiteMemberId) {
      return <CategoryPage siteMemberId={currentSiteMemberId} {...this.props} />;
    }

    if (feedType === FeedType.Categories || (!feedType && isMainPageEnabled)) {
      return <CategoriesPage {...this.props} />;
    }

    if (feedType === FeedType.Posts || (!feedType && !isMainPageEnabled)) {
      return <CategoryPage {...this.props} />;
    }

    return <CategoriesPage {...this.props} />;
  }
}

HomePage.propTypes = {
  isMainPageEnabled: PropTypes.bool.isRequired,
  isForumTabsEnabled: PropTypes.bool.isRequired,
  feedType: PropTypes.string,
  currentSiteMemberId: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  isMainPageEnabled: getIsMainPageEnabled(state, host.style),
  feedType: getFeedType(state),
  currentSiteMemberId: getCurrentUser(state)?.siteMemberId,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(HomePage);
