import { flowRight, isEmpty, get, differenceBy, head } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import { getCommentsPerPage } from '../../constants/pagination';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import AccountSuspended from '../../components/account-suspended';
import AnimatedLoader from '../../components/animated-loader';
import Button from '../../components/button';
import CommentBox from '../../components/comment-box';
import EmptyStates from '../../components/empty-states';
import Link from '../../components/link/internal-link';
import PostPageCardHeader from '../../components/card/post-page-card-header';
import PostContent from '../../components/post-content';
import PostPageLayout from '../../components/post-page-layout';
import PostPageStats from '../../components/post-page-stats';
import PostTitle from '../../components/post-title';
import { HorizontalSeparator } from '../../components/separator';
import PostCommentList from '../post-comment-list';
import PostMainActions from '../../components/post-main-actions';
import FooterPosts from '../../components/footer-posts';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getCommentsByPostId } from '../../selectors/comment-selectors';
import { getIsPostLoaded, getIsCommentsLoaded } from '../../selectors/is-loaded-selectors';
import { getIsPostLoading, getIsCommentsLoading } from '../../selectors/is-loading-selectors';
import { getEntityCount } from '../../selectors/pagination-selectors';
import { getPostBySlug } from '../../selectors/post-selectors';
import withAuth from '../../hoc/with-auth';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRouteParams, getPreviousMatches } from '../../../common/router/router-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { scrollToComment } from '../../services/scroll-to-comment';
import { triggerIosKeyboard } from '../../services/ios-utils';
import { getDeepLinkParams } from '../../services/get-deep-link-params';
import {
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_POST_EDIT,
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { MODAL_TYPE_CREATE_COMMENT } from '../../components/modals/comment-create-modal/comment-create-modal-type';
import { getIsFooterPostsEnabled } from '../../selectors/app-settings-selectors';
import {
  getCommentsPaginationConfig,
  getCommentsPaginationState,
} from '../../selectors/comments-pagination-selectors';
import { isExperimentEnabled } from '../../selectors/experiments-selectors';
import { isMembersOnly } from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { buildPageUrl } from '@wix/communities-forum-client-commons/dist/src/services/pagination';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';
import PaginationMobile from '../../components/pagination-mobile';
import styles from './post-page-mobile.scss';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_WIX_COMMENTS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import PostPageWixComments from '../wix-comments/post-page-wix-comments';

export class PostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialPage: props.page,
      currentPage: props.page,
      showCommentListLoader: false,
    };
    this.postContainerRef = React.createRef();
  }

  componentDidMount = () => {
    const { commentToFocusId, commentToFocusIdParentId, allComments } = this.props;
    if (commentToFocusId) {
      let pinTime;

      if (allComments.length >= 10) {
        pinTime = 2000;
      } else if (allComments.length >= 14) {
        pinTime = 2500;
      }
      scrollToComment(commentToFocusId, {
        isReply: commentToFocusIdParentId !== undefined,
        pinTime,
      });
    } else if (this.postContainerRef.current) {
      this.postContainerRef.current.focus({ preventScroll: true });
    }
  };

  componentDidUpdate(prevProps) {
    const { currentUser, allComments, page, commentToFocusId } = this.props;
    if (prevProps.entityCount < this.props.entityCount) {
      const newComment = head(differenceBy(allComments, prevProps.allComments, '_id'));
      const isSameOwner =
        newComment && currentUser && newComment.ownerSId === currentUser.siteMemberId;
      newComment && isSameOwner && scrollToComment(newComment._id, { shouldPinDown: false });
    }
    if (prevProps.commentToFocusId !== commentToFocusId) {
      scrollToComment(commentToFocusId);
    }
    if (prevProps.page !== page) {
      this.setState({ currentPage: page, showCommentListLoader: false });
    }
  }

  componentWillUnmount = () => {
    this.props.closeCreateCommentModal();
    this.props.setIsLoaded('comments', this.props.params.postSlug, false);

    if (!isEmpty(this.props.category)) {
      this.props.emitClosePost(this.props.post._id);
    }

    this.props.clearComments();
  };

  renderPaginationTop = () => {
    const { entityCount, commentsPerPage } = this.props;
    return (
      <PaginationMobile
        page={this.state.currentPage}
        entityCount={entityCount}
        showPerPage={commentsPerPage}
        onChange={this.handlePageChange}
        buildPageUrl={this.buildPageUrl}
        className={styles.pagination}
        isTop
      />
    );
  };

  renderPagination = () => {
    const { entityCount, commentsPerPage, isCommentsLoaded } = this.props;
    if (isCommentsLoaded && entityCount > commentsPerPage) {
      return (
        <PaginationMobile
          page={this.state.currentPage}
          entityCount={entityCount}
          showPerPage={commentsPerPage}
          onChange={this.handlePageChange}
          buildPageUrl={this.buildPageUrl}
        />
      );
    }
  };

  buildPageUrl = (page = 1) => {
    const {
      params: { postSlug, categorySlug },
    } = this.props;
    return buildPageUrl(categorySlug, postSlug, page);
  };

  handlePageChange = ({ page, buttonType }) => {
    const {
      post,
      params: { categorySlug },
      navigateWithinForum,
      changePage,
    } = this.props;
    changePage({
      page,
      meta: {
        bi: {
          buttonType,
        },
      },
    });
    navigateWithinForum(buildPageUrl(categorySlug, post.slug, page));
  };

  openCommentModal = origin => {
    const { post, openModal, userEventsReplyOrCommentIntent } = this.props;
    userEventsReplyOrCommentIntent({ type: 'comment', origin });
    triggerIosKeyboard();
    openModal(MODAL_TYPE_CREATE_COMMENT, {
      postId: post._id,
      timestamp: Date.now(),
      initiatedBy: origin === 'comment_bar' && origin,
    });
  };

  renderCommentBox = () => {
    const { isBlocked, post } = this.props;
    const isDisabled = isBlocked || post.isCommentsDisabled;
    let message;
    if (isDisabled) {
      const key = post.postType === DISCUSSION ? 'commenting' : 'answering';
      message = isBlocked ? `post-page.${key}-blocked` : `post-page.${key}-bar-off`;
    }

    return (
      <CommentBox
        post={post}
        onClick={() => this.openCommentModal('comment_bar')}
        isDisabled={isDisabled}
        message={message}
      />
    );
  };

  renderPost = () => {
    const {
      category,
      post,
      isAnimated,
      borderWidth,
      titleFontClassName,
      page,
      commentsPerPage,
      allComments,
      entityCount,
      isWixCommentsEnabled,
    } = this.props;
    const hasComments = !isEmpty(allComments);
    const hasPagination = entityCount > commentsPerPage;

    if (!isWixCommentsEnabled && page > 1) {
      return null;
    }

    const postContainerClassName = classNames(
      styles.postContainer,
      { [styles.withComments]: hasComments, [styles.withPagination]: hasPagination },
      'forum-card-border-color',
      'forum-text-color',
    );

    const postMainActions = (
      <PostMainActions
        category={category}
        post={post}
        isMobile={true}
        onReplyClick={() => this.openCommentModal('button')}
      />
    );

    return (
      <main
        ref={this.postContainerRef}
        tabIndex="-1"
        className={postContainerClassName}
        style={{ borderWidth }}
        aria-labelledby={`post-header-${post.slug}`}
      >
        <div data-hook="post-page__post">
          <div className={styles.postHeader}>
            <PostPageCardHeader post={post} />
          </div>
          <PostTitle
            className={titleFontClassName}
            type={PostTitle.POST_PAGE}
            title={post.title || ''}
            component="h1"
            id={`post-header-${post.slug}`}
          />
          <div className={styles.postContent}>
            <PostContent post={post} type={PostContent.POST_PAGE} />
          </div>
          {!isWixCommentsEnabled && (
            <div className={styles.postStatsContainer}>
              <PostPageStats
                viewCount={post.viewCount}
                commentCount={post.totalComments - post.totalReplies}
                replyCount={post.totalReplies}
                postId={post._id}
                postType={post.postType}
                shouldShowCommentsFirst
                isAnimated={isAnimated}
              />
            </div>
          )}
          {isWixCommentsEnabled ? (
            <div className={styles.footerContainer}>
              {postMainActions}
              <div className={styles.postStatsContainer}>
                <div className={styles.viewsContainer}>
                  <PostPageStats
                    viewCount={post.viewCount}
                    postId={post._id}
                    postType={post.postType}
                    isAnimated={isAnimated}
                    showCommentCount={false}
                  />
                </div>
              </div>
            </div>
          ) : (
            postMainActions
          )}
        </div>
      </main>
    );
  };

  renderEmpty = () => {
    const { t } = this.props;
    return (
      <EmptyStates
        className={styles.emptyState}
        title={t('post-page.not-found-title')}
        content={t('post-page.not-fount-content')}
      >
        <Button component={Link} to="/">
          {t('post-page.back-to-forum')}
        </Button>
      </EmptyStates>
    );
  };

  render = () => {
    const {
      params: { postSlug },
      isBlocked,
      category,
      post,
      page,
      contentFontClassName,
      isLoading,
      isLoaded,
      isCommentsLoading,
      isCommentsLoaded,
      allComments,
      commentToFocusId,
      isFooterPostsEnabled,
      isSSR,
      isWixCommentsEnabled,
    } = this.props;

    if (isEmpty(post) && !isLoading) {
      return this.renderEmpty();
    }

    if (!isEmpty(category) && isMembersOnly(category) && isBlocked) {
      return <AccountSuspended className={styles.emptyState} />;
    }

    const showCommentsLoader = isCommentsLoading && !isCommentsLoaded && isEmpty(allComments);

    return (
      <PostPageLayout
        className={classNames(
          styles.postPage,
          contentFontClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        {this.renderPaginationTop()}
        <AnimatedLoader
          className={classNames(
            styles.innerContainer,
            isWixCommentsEnabled && styles.postPageBottomSpacing, // TODO: merge with styles.innerContainer
          )}
          isLoading={isLoading && !isLoaded && isEmpty(post)}
        >
          {this.renderPost()}
          {!isWixCommentsEnabled && <HorizontalSeparator isLarge />}
          {!isWixCommentsEnabled && page === 1 && this.renderCommentBox()}
          {!isWixCommentsEnabled && (
            <AnimatedLoader isLoading={showCommentsLoader} isAnimated>
              {isEmpty(allComments) ? null : (
                <div className={classNames(styles.commentsContainer, 'forum-card-border-color')}>
                  {!isEmpty(allComments) && page <= 1 && <HorizontalSeparator isLarge />}
                  <PostCommentList
                    isDeepLinked={commentToFocusId}
                    page={this.props.page}
                    postSlug={postSlug}
                    isCommentsLoading={isCommentsLoading || this.state.showCommentListLoader}
                  />
                  <HorizontalSeparator />
                  {this.renderCommentBox()}
                  {this.renderPagination()}
                </div>
              )}
            </AnimatedLoader>
          )}
          {isWixCommentsEnabled && <PostPageWixComments post={post} />}
        </AnimatedLoader>
        {!isSSR && isFooterPostsEnabled && (
          <div className={styles.footerPosts} data-hook="footer-posts-wrapper">
            <HorizontalSeparator isLarge />
            <FooterPosts type={FooterPosts.TYPE_RELATED_POSTS} />
          </div>
        )}
      </PostPageLayout>
    );
  };
}

PostPage.propTypes = {
  category: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  allComments: PropTypes.array,
  page: PropTypes.number,
  commentToFocusId: PropTypes.string,
  emitClosePost: PropTypes.func.isRequired,
  t: PropTypes.func,
  entityCount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  isAnimated: PropTypes.object,
  borderWidth: PropTypes.number.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isCommentsLoading: PropTypes.bool.isRequired,
  isCommentsLoaded: PropTypes.bool.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  clearComments: PropTypes.func.isRequired,
  commentToFocusIdParentId: PropTypes.string,
  userEventsReplyOrCommentIntent: PropTypes.func,
  isFooterPostsEnabled: PropTypes.bool,
  isSSR: PropTypes.bool,
  openModal: PropTypes.func,
  closeCreateCommentModal: PropTypes.func,
  changePage: PropTypes.func,
  currentUser: PropTypes.object,
  isWixCommentsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const post = getPostBySlug(state, params.postSlug) || {};
  const page = parseInt(params.page, 10) || 1;
  const { commentToFocusId, parentId } = getDeepLinkParams(params.deepLinkData);
  const prevRoute = get(getPreviousMatches(state)[1], 'route'); // take 1st member, since 0 is the current route
  const isWixCommentsEnabled = isExperimentEnabled(state, EXPERIMENT_WIX_COMMENTS);
  const commentsPaginationState = getCommentsPaginationState(state, post._id);
  const commentsPaginationConfig = getCommentsPaginationConfig(state, post._id);
  const entityCount = isWixCommentsEnabled
    ? commentsPaginationState?.totals?.topLevelComments
    : getEntityCount(state, 'comments', post._id);
  const commentsPerPage = isWixCommentsEnabled
    ? commentsPaginationConfig?.pagination?.commentLimit
    : getCommentsPerPage(true);

  return {
    isAnimated: [
      ROUTE_CREATE_POST,
      ROUTE_CREATE_QUESTION,
      ROUTE_CATEGORY_CREATE_POST,
      ROUTE_CATEGORY_CREATE_QUESTION,
      ROUTE_POST_EDIT,
    ].includes(prevRoute),
    category: getCategoryBySlug(state, params.categorySlug) || {},
    post,
    allComments: getCommentsByPostId(state, post._id),
    isLoading: getIsPostLoading(state, params.postSlug),
    isLoaded: getIsPostLoaded(state, params.postSlug),
    isCommentsLoading: getIsCommentsLoading(state, params.postSlug),
    isCommentsLoaded: getIsCommentsLoaded(state, params.postSlug),
    entityCount,
    page,
    changePage: actions.changePage,
    commentToFocusId,
    commentToFocusIdParentId: parentId,
    emitClosePost: actions.emitClosePost,
    setIsLoaded: actions.setIsLoaded,
    navigateWithinForum: actions.navigateWithinForum,
    clearComments: actions.clearComments,
    openModal: actions.openModal,
    closeCreateCommentModal: () => actions.closeModal({ type: MODAL_TYPE_CREATE_COMMENT }),
    userEventsReplyOrCommentIntent: actions.userEventsReplyOrCommentIntent,
    isSSR: isSSR(state),
    params,
    isFooterPostsEnabled: getIsFooterPostsEnabled(state, host.style),
    commentsPerPage,
    currentUser: getCurrentUser(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [
    REDUCERS.BASIC_PARAMS,
    REDUCERS.ROUTER,
    REDUCERS.CATEGORIES,
    REDUCERS.POSTS,
    REDUCERS.COMMENTS,
    REDUCERS.IS_LOADING,
    REDUCERS.IS_LOADED,
    REDUCERS.PAGINATION,
  ]),
  withFontClassName,
  withCardBorderWidth,
  withAuth,
  withTranslate,
  withExperiment({
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
  }),
)(PostPage);
