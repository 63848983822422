import React, { FC, useState, KeyboardEvent, ChangeEvent, useRef } from 'react';
import { flowRight } from 'lodash';
import withTranslate from '../../../common/components/with-translate/with-translate';
import classNames from 'classnames';
import { TFunction } from '@wix/wix-i18n-config';
import ClearIcon from 'wix-ui-icons-common/on-stage/Clear';
import SearchIcon from 'wix-ui-icons-common/on-stage/SearchSmall';
// @ts-expect-error
import { CSSTransitionGroup } from 'react-transition-group';
import { handleEnterKeyUp, handleEscapeKeyUp } from '../../services/accessibility';
import styles from './search-input-suggestions.scss';

const CLEAR_ANIMATION_TIMEOUT = 400;

interface SearchInputSuggestionsProps {
  t: TFunction;
  onSearch: (query: string) => void;
  onFocus?: () => void;
  onClear?: () => void;
  initialQuery?: string;
}

const SearchInputSuggestions: FC<SearchInputSuggestionsProps> = ({
  t,
  onSearch,
  onFocus,
  onClear,
  initialQuery = '',
}) => {
  const [value, setValue] = useState<string>(initialQuery);
  const [isInputFocused, setInputFocused] = useState<boolean>(false);

  const handleEscape = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLElement;
    target.blur();
  };

  const handleEnter = () => {
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    handleEnterKeyUp(handleEnter)(event);
    handleEscapeKeyUp(handleEscape)(event);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClearButtonClick = () => {
    setValue('');
    onClear?.();
  };

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.containerFocused]: isInputFocused,
      })}
      role="search"
      aria-label={t('search-input.placeholder')}
      tabIndex={0}
    >
      <SearchIcon className={styles.searchIcon} data-hook="search-icon" />
      <form onSubmit={ev => ev.preventDefault()} autoComplete="off">
        <input
          data-hook="search-input"
          className={styles.searchInput}
          onChange={handleOnChange}
          value={value}
          type="text"
          autoComplete="off"
          placeholder={t('search-input.placeholder')}
          onKeyUp={handleKeyUp}
          onFocus={() => {
            setInputFocused(true);
            onFocus?.();
          }}
          onBlur={() => setInputFocused(false)}
        />
        <CSSTransitionGroup
          component={React.Fragment}
          transitionName="clearButton"
          transitionEnterTimeout={CLEAR_ANIMATION_TIMEOUT}
          transitionLeaveTimeout={CLEAR_ANIMATION_TIMEOUT}
        >
          {!!value && (
            <button
              className={styles.clearButton}
              type="button"
              onClick={handleClearButtonClick}
              onMouseDown={e => e.preventDefault()}
              tabIndex={-1}
              data-hook="search-clear-button"
            >
              <ClearIcon className={styles.clearIcon} />
            </button>
          )}
        </CSSTransitionGroup>
      </form>
    </div>
  );
};

export default flowRight(withTranslate)(SearchInputSuggestions);
